import axios from "@/utils/axios"
import api from "@/api"
// 商品类别
export function categorys(){
  return api.get("/shop/categoryList")
}
// 获取商品列表
export function getGoodsList(type,search ) {
  console.log("get goods list:");
  return api.get("/shop/list", {
    params:{
      type:type,
      search:search
    }
  })
}
// 获取商品详情
export function getGoodsDetail(id) {
  return api.get("/shop/shopInfo", {
    params:{
      id:id
    }
  });
}

// 获取商品规格详情
export function getBookStandard( id) {
  return api.get("/shop/getBookStandard", {
    params:{
      id:id
    }
  });
}
// 获取商品可用优惠券
export function getCouponList( params ) {
  return axios.get("/mock/goods/getCouponList", params);
}
// 获取购物车商品
export function getGoodsListByCart( params ) {
  return axios.get("/mock/goods/getListByCart", params);
}

// 加入购物车
export function addShopCar( params ) {
  return api.post("/shop/addShopCar", params);
}

// 我的购物车数量
export function myCarNum( userId ) {
  return api.get("/shop/shopCarNum", {
    params:{
      userId:userId
    }
  });
}

// 删除购物车
export function deleteCar( id ) {
  return api.get("/shop/deleteShopCar", {
    params:{
      id:id
    }
  });
}

// 购物车列表
export function shopCarList( userId ) {
  return api.get("/shop/shopCarList", {
    params:{
      userId:userId
    }
  });

}
// 购买书籍
export function wxPay( params ) {
  return api.post("/shop/wxPay",params);
}

// 订单详情
export function orderInfo( params ) {
  return api.post("/shop/orderInfo",params);
}

// 购物车商品数量增减
export function addOrRedu( params ) {
  return api.post("/shop/addOrRedu",params);
}
