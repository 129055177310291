<template>
  <div class="detail">
    <van-image
      fit="cover"
      :src="pics"
    />


    <div class="sell">
      <!--<div class="price" v-if="!goods.options || !Array.isArray(goods.options) || goods.options.length < 1 ">-->
      <!--<span class="min-price">价格无效</span>-->
      <!--</div>-->
      <!--<div class="price" v-else-if="optionIndex > -1">-->
      <!--<span class="min-price">￥{{ goods.options[optionIndex].price }}</span>-->
      <!--</div>-->
      <!--<div class="price" v-else-if="goods.price == goods.maxPrice">-->
      <!--<span class="min-price">￥{{ goods.price }}</span>-->
      <!--</div>-->
      <!--<div class="price" v-else>-->
      <!--<span class="min-price">￥{{ goods.price }}</span><span class="to">-</span><span class="max-price">{{ goods.maxPrice }}</span>-->
      <!--</div>-->
      <div class="price">
        <span style="color: #707070;">售价:￥{{ goods.marketPrice }}</span>
        <span style="margin-left: 30px;">全站会员价:￥{{ goods.vipPrice }}</span>
      </div>
      <!--<div v-else class="price">-->
      <!--<span >￥{{ goods.price }}</span>-->
      <!--</div>-->
      <!--<div class="amount">{{goods.buyCount}} 人买过</div>-->
    </div>
    <div class="describ">
      <span class="title">商品描述</span>
      <span class="content">{{goods.describ}}</span>
    </div>
    <div class="standard">
      <van-radio-group v-model="checked" direction="horizontal">
        <van-radio style="margin-top: 10px" v-for="(item,index) in goods.packs" :key="index" :name="index" @click="getChecked(item)">
          {{item.name}}
        </van-radio>
      </van-radio-group>
    </div>

    <!--<div class="goods-name">-->
      <!--<div class="name van-ellipsis">{{ goods.name }}</div>-->
      <!--<div class="subtitle">{{ goods.intro }}</div>-->
    <!--</div>-->

    <div class="delivery">
      <div class="label">发货</div>
      <div class="content">
        <div class="title">实付满49包邮（除新疆、西藏地区）</div>
        <div class="subtitle">付款后三个工作日内发货</div>
      </div>
    </div>
    <img :src="goods.detailImg" style="width: 100%;"/>

    <van-action-bar>
      <van-action-bar-icon icon="chat-o" text="客服" @click="showKefuPopup"/>
      <!--<van-action-bar-icon icon="share-o" text="分享" @click="shareGoods"/>-->
      <van-action-bar-icon icon="cart-o" text="购物车" :badge="carNum" @click="toCart"/>
      <van-action-bar-button type="warning" text="加入购物车" @click="addCar"/>
      <van-action-bar-button type="danger" text="立即购买" @click="buyNow"/>
      <!--<van-action-bar-button type="danger" if="goodsStandard.num<1" text="暂时缺货"  />-->
    </van-action-bar>

    <van-popup v-model:show="showKefu" class="kefuPopup">
      <div class="kefu-tips">长按下方二维码图片，选择“打开对方的企业微信名片”</div>
      <van-image width="206px" height="206px" :src="require('@/assets/images/1d23d0377d605c0aa4cb8fd6e519ffb.png')"/>
    </van-popup>

    <van-share-sheet
      v-model:show="showShare"
      title="立即分享给好友"
      :options="options"
    />
  </div>
</template>

<script>
  import {ref, onMounted} from 'vue';
  import {showToast} from 'vant';
  import {useRouter} from 'vue-router'
  import {getGoodsDetail} from '@/service/goods'
  import {addShopCar,myCarNum} from '@/service/order'
  import { isDo} from '@/service/course';
  export default {
    setup() {
      const pics = ref("");
      const judge=ref(1);//是否购买或加入购物车的状态 1：可以操作 -1：库存不足 -2：全站会员不可以买单课
      const checked = ref(0);
      const router = useRouter();
      const goods = ref({});
      // const goodsStandard=ref({});
      const carNum = ref(0);
      const showKefu = ref(false);
      const showShare = ref(false);
      const loginFlag = ref(false);//未登录
      const couponList = ref([]); // 商品可用优惠券
      const optionIndex = ref(-1);  // 商品选项默认
      const primaryIndex = ref(0);  // 商品选项预选
      const couponIndex = ref(-1);  // 用户选用的优惠券
      const optionPopup = ref(false); // 选项弹出层
      const goodsId = router.currentRoute.value.query.goodsId;
      const storage = localStorage.getItem("token-hyxt");
      const token = JSON.parse(storage);
      const product = ref({});
      let products = [];
      const options = [
        {index:0,name: '微信', icon: 'wechat'},
        {index:1,name: '朋友圈', icon: 'wechat-moments'},
        {index:2,name: '复制链接', icon: 'link', description: '描述信息'},
        {index:3,name: '二维码', icon: 'qrcode'},
      ];

      const onLoad = async () => {
        let res = await getGoodsDetail(goodsId);
        goods.value = res.data.data;
        console.log("shopInfo")
        console.log(res.data.data)
        goods.value.packId = goods.value.packs[0].id;
        goods.value.marketPrice = goods.value.packs[0].marketPrice;
        goods.value.vipPrice = goods.value.packs[0].vipPrice;
        goods.value.detailImg=goods.value.packs[0].detailImg;
        pics.value = goods.value.packs[0].introImg;
        console.log("introimg")
        console.log(pics.value)
        getJudgeStatus(goodsId,goods.value.packId,token.userId);
      };
      // 显示选项
      const showOptions = () => {
        if (!goods.value.options || !Array.isArray(goods.value.options) || goods.value.options.length < 1) {
          showToast("该商品没有设置选项");
          return;
        }
        primaryIndex.value = optionIndex.value > -1 ? optionIndex.value : 0;
        optionPopup.value = true;
      }

      const getJudgeStatus = async (courseShopId,packId,userId) =>{
        isDo (courseShopId,packId,userId).then(res=>{
          judge.value=res.data.status;
          console.log("获取判断值")
          console.log(judge.value)
        })
      }
      const selectOption = (index) => {
        primaryIndex.value = index;
      }
      // 显示客服
      const showKefuPopup = () => {
        location.href = "https://work.weixin.qq.com/kfid/kfc1183239a0a660ddc"
      };

      // 分享
      const shareGoods = () => {
        showShare.value = true;
      }
      // 立即购买
      const buyNow = () => {
        if (loginFlag.value) {
          if(-1==judge.value){
            showToast("该书籍暂无库存！");
          }else{
            product.value.packId = goods.value.packId;
            product.value.amount = 1;
            products.push(product.value);
            router.push({path: "/pay/book", query: {goods: JSON.stringify(products)}});
          }

        } else {

          router.push("/login");
        }


      }
      // 显示购物车
      const toCart = () => {
        if (loginFlag.value) {
          if(carNum.value>0){
            router.push("/cart");
          }else{
            showToast("购物车空空如也");
          }

        } else {
          router.push("/login");
        }

      }
      const addCar = () => {
        if (loginFlag.value) {
          //已登录
          if(-1==judge.value){
            showToast("该书籍暂无库存！");
          }else{
            addShopCar({packId: goods.value.packId, userId: token.userId}).then(res2 => {
              if (res2.data.status == 1) {
                showToast("加入购物车成功")
                carNum.value = parseInt(carNum.value) + 1;
              }
            })
          }
        } else {
          router.push("/login");
        }


      }

      // 确认选项
      const confirmOption = () => {
        optionIndex.value = primaryIndex.value;
        optionPopup.value = false;
      }
      //radion选中事件
      const getChecked = (item) => {
        goods.value.marketPrice = item.marketPrice;
        goods.value.vipPrice = item.vipPrice;
        goods.value.packId = item.id;
        pics.value=item.introImg;
        goods.value.detailImg=item.detailImg;
        console.log(goods.value.packId)
        getJudgeStatus(goodsId,goods.value.packId,token.userId);
      }
      onMounted(() => {
        console.log("购物车数量")
        if (token) {
          loginFlag.value = ref(true);
          myCarNum(token.userId).then(res => {
            carNum.value = res.data.data;
          })

        }
        onLoad();

      })


      return {
        goods,
        carNum,
        pics,
        options,
        optionPopup,
        optionIndex,
        primaryIndex,
        couponIndex,
        couponList,
        onLoad,
        showOptions,
        selectOption,
        showKefu,
        showKefuPopup,
        showShare,
        shareGoods,
        buyNow,
        addCar,
        toCart,
        confirmOption,
        checked,
        getChecked,
      }
    }
  }
</script>

<style lang="scss">
  .detail {
    font-size: 24px;
    // background-color: #f5f5f5;
    position: relative;
    .sell {
      line-height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      padding: 0 16px;
      padding-top: 8px;
      .price {
        color: #F63515;
        font-size: 30px;
        font-weight: 500;
        .to {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
      .amount {
        color: #707070;
      }
    }
    .standard {
      margin: 10px 8px;
    }
    .describ{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 16px;
      .title{
        font-weight: bold;
      }
      .content{
        display: block;
        width: 500px;
        margin-left: 130px;
        margin-top: -30px;
      }
    }
    .coupon {
      line-height: 56px;
      background-color: #fff;
      padding: 0 16px;
      font-size: 24px;
      color: rgb(242, 85, 0);
      position: relative;
      &::after {
        position: absolute;
        box-sizing: border-box;
        content: " ";
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid var(--van-cell-border-color);
        transform: scaleY(.5);
      }
    }
    /*.goods-name {*/
      /*padding: 16px 16px;*/
      /*background: #fff;*/
      /*position: relative;*/
      /*.name {*/
        /*font-size: 28px;*/
        /*font-weight: 500;*/
        /*line-height: 56px;*/
      /*}*/
      /*.subtitle {*/
        /*line-height: 32px;*/
      /*}*/
      /*&::after {*/
        /*position: absolute;*/
        /*box-sizing: border-box;*/
        /*content: " ";*/
        /*pointer-events: none;*/
        /*right: 16px;*/
        /*bottom: 0;*/
        /*left: 16px;*/
        /*border-bottom: 1px solid var(--van-cell-border-color);*/
        /*transform: scaleY(.5);*/
      /*}*/
    /*}*/
    .delivery {
      display: flex;
      justify-content: flex-start;
      padding: 16px 16px;
      background: #fff;
      line-height: 36px;
      position: relative;
      .label {
        width: 80px;
        // color: #707070;
      }
      .content {
        .title {

        }
        .subtitle {
          color: #707070;
        }
      }
      &::after {
        position: absolute;
        box-sizing: border-box;
        content: " ";
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid var(--van-cell-border-color);
        transform: scaleY(.5);
      }
    }
    .options {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 24px 16px 24px;
      background: #fff;
      line-height: 36px;
      .label {
        width: 80px;
        // color: #707070;
      }
      .title {
        display: flex;
        flex-wrap: wrap;
        color: #707070;
        .warning {
          color: var(--van-button-warning-background-color);
        }
        .selected {
          margin-left: 16px;
          padding: 8px 16px;
          color: #fff;
          background-color: #FF9966;
          border-radius: 8px;
        }
      }
      .title {
        flex: 1;
      }
    }
    .evaluation {
      margin-top: 8px;
      padding: 18px 16px;
      background-color: #fff;
      > .header {
        font-size: 28px;
        line-height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label {
          font-weight: 600;
        }
        .amount {
          color: #707070;
        }
      }
      .top3 {
        display: flex;
        flex-direction: column;
        .item-box {
          padding-top: 12px;
          padding-bottom: 12px;
          .header {
            display: flex;
            align-items: center;
            line-height: 40px;
            .info-right {
              padding-left: 12px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .nickname {
                font-weight: 600;
              }
              .label {
                color: #707070;
              }
              .option-title {
                margin-left: 16px;
                padding: 8px 16px;
                color: #fff;
                background-color: #FF9966;
                border-radius: 8px;
              }
            }
          }
          .content {
            margin-top: 12px;
          }
        }
      }
    }
    .preface {
      margin-top: 8px;
      padding: 18px 16px;
      background-color: #fff;
      > .header {
        font-size: 28px;
        font-weight: 600;
        line-height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .content {
        display: flex;
        flex-direction: column;
        padding-top: 12px;
        margin-bottom: 80px;
      }
    }
    .optionPopup {
      padding: 24px 24px;
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(100% - 48px);
      height: calc(100% - 48px);
      .header {
        display: flex;
        .info-right {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .price {
            color: #F63515;
            font-size: 40px;
            font-weight: 500;
          }
          .label {
            width: 80px;
            color: #707070;
          }
        }
      }
      .content {
        margin-top: 24px;
        .all {
          display: flex;
          flex-wrap: wrap;
          margin-top: 16px;
          .item {
            padding: 8px 16px;
            border-radius: 8px;
            white-space: nowrap;
            margin-bottom: 16px;
            margin-right: 16px;
            border: 1px solid #cacaca;
          }
          > .active {
            border: 0;
            color: #fff;
            background-color: #FF9966;
          }
        }
      }
      .confirm {
        position: absolute;
        width: calc(100% - 48px);
        bottom: 30px;
      }
    }
    .kefuPopup {
      width: 370px;
      height: 440px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .kefu-tips {
        color: #F76968;
        font-size: 24px;
        padding-top: 8px;
        padding-left: 6px;
        padding-right: 6px;
        line-height: 34px;
        text-align: center;
      }
    }
  }

  .custom-indicator {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 6px 18px;
    font-size: 24px;
    color: #fff;
    letter-spacing: 3px;
    background: rgba(0, 0, 0, 0.1);
  }

  .van-image {
    vertical-align: top;
  }

</style>
